:root {
  scroll-behavior: smooth;
  font-size: calc(1vw + .6em);
}

body {
  font-family: Kanit, sans-serif;
  font-weight: 300;
  position: relative;
}

*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizespeed;
  line-height: 1.5;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

.top {
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  display: flex;
}

.logo img {
  width: 120px;
}

.menu-toggle {
  width: 2.4em;
  height: .24em;
  text-indent: -9999em;
  background-color: #000;
  border: 0;
  line-height: 0;
  position: relative;
}

.menu-toggle:after {
  width: 2.4em;
  height: .24em;
  content: "";
  background-color: #000;
  position: absolute;
  top: -.5em;
  left: 0;
}

.menu-toggle:before {
  width: 2.4em;
  height: .24em;
  content: "";
  background-color: #000;
  display: block;
  position: absolute;
  top: .5em;
  left: 0;
}

.menu-dropdown {
  width: 19em;
  height: 100vh;
  z-index: 11;
  background-color: #f68913;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: all .3s ease-in-out .2s;
  position: absolute;
  top: 0;
  left: -19em;
}

.main-nav.is-open .menu-dropdown {
  left: 0;
}

.main-nav.is-open .menu-toggle:before {
  display: none;
}

.main-nav.is-open .menu-toggle:after {
  top: 0;
  transform: rotate(90deg);
}

.main-nav.is-open .menu-toggle {
  transform: rotate(-45deg);
}

.nav-item {
  border-bottom: 1px solid #000;
  padding: .25em 1em;
}

.nav-item:first-child {
  padding: .5em 1em;
}

.nav-item:last-child {
  border-bottom: 0;
  padding: .5em 1em;
}

.nav-item a {
  color: #000;
  text-decoration: none;
  transition: color .2s ease-in-out;
}

.nav-item a:hover, .nav-item a:focus {
  color: #f68913;
}

aside {
  position: relative;
}

aside:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #000000a6;
  position: absolute;
  top: 0;
  left: 0;
}

aside p {
  color: #fff;
  z-index: 10;
  letter-spacing: .5px;
  margin: 0;
  font-size: 1.5em;
  position: absolute;
  top: 50%;
  left: 1em;
  transform: translateY(-50%);
}

aside p span {
  color: #f68913;
}

h2, legend {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

section {
  padding: 2em 0;
}

.wrapper {
  width: 92%;
  margin: 0 auto;
}

.about {
  background-color: #f68913;
  padding: 3em 0;
}

.about img {
  margin-bottom: 1.5em;
}

.contact span {
  font-weight: 400;
}

.contact p {
  margin-bottom: 1em;
}

.contact p a {
  color: #000;
  text-decoration: none;
}

form {
  margin-top: 2em;
}

fieldset {
  border: 0;
  padding: 0;
}

label {
  display: block;
}

.form-input, .form-content {
  width: 100%;
  margin-bottom: 1em;
}

.form-check-input {
  margin: 0;
}

.btn {
  text-transform: uppercase;
  background-color: #f68913;
  border: 0;
  margin-top: 1.25em;
  padding: .3em 1.5em;
  font-family: Kanit, sans-serif;
  font-size: 1.25rem;
  transition: color .2s ease-in-out;
}

.btn:hover, .btn:focus {
  color: #fff;
}

footer {
  color: #fff;
  text-align: center;
  background-color: #333;
  padding: .8em 0;
}

.back-to-top {
  width: 40px;
  height: 40px;
  background-color: #f68913;
  border-radius: 50%;
  position: absolute;
  bottom: 50px;
  right: 20px;
}

.back-to-top a {
  width: 100%;
  height: 100%;
  display: block;
}

.back-to-top a:after, .back-to-top a:before {
  width: 15px;
  height: 3px;
  content: "";
  background-color: #fff;
  position: absolute;
  top: 17px;
  left: 17px;
  transform: rotate(45deg);
}

.back-to-top a:before {
  top: 17px;
  left: 8px;
  transform: rotate(-45deg);
}

.ok {
  color: #08a10d;
}

.error {
  color: red;
}

@media screen and (min-width: 46em) {
  .logo img {
    width: 150px;
  }

  .menu-toggle {
    display: none;
  }

  .menu-dropdown {
    height: auto;
    background-color: #0000;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    position: relative;
    top: 0;
    left: 0;
  }

  .nav-item {
    border-bottom: 0;
  }

  .about .wrapper {
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
  }

  .about .col:first-child {
    width: 40%;
  }

  .about .col:last-child {
    width: 60%;
    margin-top: -.4em;
    padding-left: 1.5em;
  }

  .contact .wrapper {
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
  }

  .contact .col:first-child {
    width: 40%;
  }

  .contact .col:last-child {
    width: 60%;
  }

  form {
    margin-top: 0;
  }

  .form-check-info {
    font-size: .8em;
  }

  .back-to-top {
    width: 50px;
    height: 50px;
  }

  .back-to-top a:after, .back-to-top a:before {
    top: 23px;
    left: 22px;
  }

  .back-to-top a:before {
    top: 23px;
    left: 13px;
  }

  footer {
    font-size: .8em;
  }
}

@media screen and (min-width: 64em) {
  .logo img {
    width: 100%;
  }

  .btn {
    cursor: pointer;
    font-size: 1rem;
  }

  .back-to-top {
    bottom: 70px;
  }
}

@media screen and (min-width: 90em) {
  body {
    font-size: .8em;
  }

  .top {
    max-width: 1400px;
    margin: 0 auto;
  }

  aside p {
    font-size: 2em;
    left: 5em;
  }

  .wrapper {
    max-width: 1024px;
  }

  .btn {
    font-size: .8rem;
  }
}

/*# sourceMappingURL=index.7b7dbd9a.css.map */
