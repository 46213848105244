:root {
    font-size: calc(1vw + 0.6em);
    scroll-behavior: smooth;
}

body {
  font-family: 'Kanit', sans-serif;
  font-weight: 300;
  position: relative;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.top {
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  display: flex;
}

.logo img {
  width: 120px;
}

.menu-toggle {
  width: 2.4em;
    height: 0.24em;
    text-indent: -9999em;
    background-color: #000;
    border: 0;
    line-height: 0;
    position: relative;
}

.menu-toggle::after {
  width: 2.4em;
  height: 0.24em;
  content: "";
  background-color: #000;
  position: absolute;
  top: -0.5em;
  left: 0;
}

.menu-toggle::before {
  width: 2.4em;
  height: 0.24em;
  content: "";
  background-color: #000;
  position: absolute;
  top: 0.5em;
  left: 0;
  display: block;
}

.menu-dropdown {
  width: 19em;
  height: 100vh;
  z-index: 11;
  background-color: #f68913;
  position: absolute;
  top: 0;
  left: -19em;
  transition: all 0.3s ease-in-out 0.2s;
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-nav.is-open .menu-dropdown {
  left: 0;
}

.main-nav.is-open .menu-toggle::before {
  display: none;
}

.main-nav.is-open .menu-toggle::after {
  transform: rotate(90deg);
  top: 0;
}

.main-nav.is-open .menu-toggle {
  transform: rotate(-45deg);
}

.nav-item {
  padding: 0.25em 1em;
  border-bottom: 1px solid #000;
}

.nav-item:first-child {
  padding: 0.5em 1em;
}

.nav-item:last-child {
  border-bottom: 0;
  padding: 0.5em 1em;
}

.nav-item a {
  text-decoration: none;
  color: #000;
  transition: color 0.2s ease-in-out;
}

.nav-item a:hover, .nav-item a:focus {
  color: #f68913;
}

aside {
  position: relative;
}

aside::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 65%);
}

aside p {
  color: #fff;
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 1em;
  transform: translateY(-50%);
  margin: 0;
  letter-spacing: .5px;
  font-size: 1.5em;
}

aside p span {
  color: #f68913;
}

h2, legend {
  position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap; /* added line */
      border: 0;
}

section {
  padding: 2em 0;
}

.wrapper {
  width: 92%;
  margin: 0 auto;
}

.about {
  background-color: #f68913;
  padding: 3em 0;
}

.about img {
  margin-bottom: 1.5em;
}

.contact span {
  font-weight: 400;
}

.contact p {
  margin-bottom: 1em;
}

.contact p a {
  text-decoration: none;
  color: #000;
}

form {
  margin-top: 2em;
}

fieldset {
  border: 0;
  padding: 0;
}

label {
  display: block;
}

.form-input, .form-content {
  width: 100%;
  margin-bottom: 1em;
}

.form-check-input {
  margin: 0;
}

.btn {
    background-color: #f68913;
    padding: 0.3em 1.5em;
    border: 0;
    font-family: 'Kanit', sans-serif;
    font-size: 1.25rem;
    text-transform: uppercase;
    margin-top: 1.25em;
    transition: color 0.2s ease-in-out;
}

.btn:hover, .btn:focus {
  color: #fff;
}

footer {
  background-color: #333;
  color: #fff;
  padding: 0.8em 0;
  text-align: center;
}

.back-to-top {
  position: absolute;
  right: 20px;
  bottom: 50px;
  width: 40px;
  height: 40px;
  background-color: #f68913;
  border-radius: 50%;
}

.back-to-top a {
  display: block;
  width: 100%;
  height: 100%;
}

.back-to-top a::after, .back-to-top a::before {
  position: absolute;
  width: 15px;
  height: 3px;
  background-color: #fff;
  content: '';
  transform: rotate(45deg);
  top: 17px;
  left: 17px;
}

.back-to-top a::before {
  transform: rotate(-45deg);
  top: 17px;
  left: 8px;
}

.ok {
  color: #08a10d;
}

.error {
  color: #f00;
}

@media screen and (min-width: 46em) {

  .logo img {
    width: 150px;
  }
  
  .menu-toggle {
    display: none;
  }
  
  .menu-dropdown {
    position: relative;
    top: 0;
    left: 0;
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: auto;
  }

  .nav-item {
    border-bottom: 0;
  }

  .about .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .about .col:first-child {
    width: 40%;
  }

  .about .col:last-child {
    width: 60%;
    padding-left: 1.5em;
    margin-top: -0.4em;
  }

  .contact .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .contact .col:first-child {
    width: 40%;
  }

  .contact .col:last-child {
    width: 60%;
  }

  form {
    margin-top: 0;
  }
  
  .form-check-info {
    font-size: 0.8em;
  }
  
  .back-to-top {
    width: 50px;
    height: 50px;
  }

  .back-to-top a::after, .back-to-top a::before {
    top: 23px;
    left: 22px;
  }

  .back-to-top a::before {
    top: 23px;
    left: 13px;
  }

  footer {
    font-size: 0.8em;
  }

}

@media screen and (min-width: 64em) {
  
  .logo img {
    width: 100%;
  }

  .btn {
    font-size: 1rem;
    cursor: pointer;
  }

.back-to-top {
  bottom: 70px;
}

}

@media screen and (min-width: 90em) {

  body {
    font-size: 0.8em;
  }
  
  .top {
    max-width: 1400px;
    margin: 0 auto;
  }

  aside p {
    font-size: 2em;
    left: 5em;
  }

  .wrapper {
    max-width: 1024px;
  }

  .btn {
    font-size: 0.8rem;
  }

}